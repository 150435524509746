import * as THREE from 'three'
import React, { Suspense, useMemo, useState, useEffect } from 'react'
import { Canvas, useThree } from 'react-three-fiber'
import { Html, Loader } from '@react-three/drei'
import './styles.css'
import { v4 as uuidv4 } from 'uuid';
import * as lodash from 'lodash';
import { FcInfo } from 'react-icons/fc';
import tourcss from './R3DTour.module.css';
import { IoMdCloseCircle } from 'react-icons/io';
import styled from 'styled-components';
import * as TWEEN from '@tweenjs/tween.js';
import DOMPurify from 'dompurify';
import CameraControls from 'camera-controls';

CameraControls.install( { THREE: THREE } );

const initialZoom = 1;
const FirstContext = React.createContext({});

function animate(time) {
    window.requestAnimationFrame(animate);
    TWEEN.update(time);
}

const Controls = React.forwardRef((props, ref) => {
    const [state, setState] = useState([0,0]);
    const { three, onRotationChange, ...p } = props;
    const controls = React.useMemo(() => {
        var cam = new CameraControls(three.camera, three.gl.domElement);
        if(ref !== null) ref.current = cam;
        for(var key in p) {
            cam[key] = p[key];
        }
        return cam;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        for(var key in p) {
            controls[key] = p[key];
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [p]);
    useEffect(() => {
        const clock = new THREE.Clock();
        ( function anim () {

            // snip
            try {
                const delta = clock.getDelta();
                controls.update( delta );

                const angle = [controls.azimuthAngle, controls.polarAngle];
                if (!lodash.isEqual(state, angle)) {
                    setState(angle);
                    if ("onRotationChange" in props) props.onRotationChange(angle);
                }
            
            } catch(e) {}
        
            requestAnimationFrame( anim );
        
        } )();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    /*useFrame(() => {
        const angle = controls !== null ? [controls.azimuthAngle, controls.polarAngle] : [0,0];
        if (!lodash.isEqual(state, angle)) {
            setState(angle);
            if ("onRotationChange" in props) props.onRotationChange(angle);
        }
        /*if(controls !== null) {
            controls.update();
        }
    });*/
    return <primitive object={controls}/>
    //return <ThreeOrbitControls ref={ref} {...p} args={[three.camera, three.gl.domElement]}/>
});

class PanelButtons extends React.Component {
    render() {
        if (this.props.children === undefined) return null;
        const width = this.props.dimension.width / React.Children.count(this.props.children);
        const aa = [];
        React.Children.forEach(this.props.children, value => {
            const C = value.type;
            aa.push(<C key={uuidv4()} {...value.props} style={{ ...value.props.style, width: width }} />);
        })
        return (
            <div className={tourcss.panelButtons} style={{ ...this.props.dimension }}>
                {aa}
            </div>
        )
    }
}

function Preload(props) {
    const { maps, render } = props;
    const textures = useMemo(() => {
        const temp = [];
        const loader = new THREE.TextureLoader();
        document.getElementById("videos").innerHTML = '';
        for (var i = 0; i < maps.length; i++) {
            const item = maps[i];
            if (item.url.endsWith(".mp4")) {
                const tag = document.createElement("video");
                document.getElementById("videos").append(tag);
                tag.muted = true;
                tag.preload = "auto";
                tag.crossOrigin = "anonymous";
                tag.playsInline = true;
                tag.loop = true;
                tag.src = item.url;
                const t = new THREE.VideoTexture(tag);
                t.wrapS = THREE.ClampToEdgeWrapping;
                t.wrapT = THREE.ClampToEdgeWrapping;
                //t.repeat.x = -1;
                temp.push(t);
            } else {
                temp.push(loader.load(item.url));
            }
        }
        return temp;
    }, [maps]);
    return render(textures);
}

function InitThree({ render }) {
    const three = useThree();
    useEffect(() => {
        three.camera.zoom = initialZoom;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return render(three);
}

class Item extends React.Component {
    constructor(props) {
        super(props);
        this.panelDim = { width: 350, height: 460 };
        this.panelPadding = 10
        this.maxImageHeight = 180
        this.buttonsHeight = 50
        this.titleBarHeight = 60;
        this.state = {
            panel: false
        };
    }

    render() {
        const textPanelHeight = this.panelDim.height - this.maxImageHeight - this.buttonsHeight - this.titleBarHeight - 5;
        const otherStyle = this.state.panel ? {display: 'block'} : {display: 'none'};
        return (
            <mesh position={this.props.position}>
                <Html center style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginTop: this.state.panel ? -this.panelDim.height / 2 : 0 }}>
                    <div style={{ ...this.panelDim, backgroundColor: 'white', position: 'relative', ...otherStyle}}>
                        <div style={{ width: this.panelDim.width, height: this.titleBarHeight, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 32 }}>
                            {this.props.title}
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', width: this.panelDim.width }}>
                            <img alt=""
                                src={this.props.imageURL}
                                style={{ maxWidth: this.panelDim.width, padding: this.panelPadding, maxHeight: this.maxImageHeight }}
                            />
                        </div>
                        <div className={"customScrollDiv"} style={{ overflowY: 'auto', paddingLeft: 10, paddingRight: 5, height: textPanelHeight, marginRight: 5 }}
                            dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(this.props.description)}}
                        />
                        <PanelButtons dimension={{ width: this.panelDim.width, height: this.buttonsHeight }}>
                            <div onClick={() => this.props.onCartAdd({asd: "CIAONE"})}>Add to Cart</div>
                            <div onClick={() => window.open("https://www.amazon.it", "_blank")}>Buy</div>
                            <div onClick={() => window.open("tel:0808978493")}>Call</div>
                        </PanelButtons>
                    </div>
                    {this.state.panel ?
                        <IoMdCloseCircle className={tourcss.closeButton} size={30} color="red" onClick={() => this.setState({panel: false})}/> :
                        <FcInfo size={30} className={tourcss.infoButton} onClick={() => this.setState({panel: true})}/>
                    }
                </Html>
            </mesh>
        )
    }
}

function LocationPoint(props) {
    const [state, setState] = useState({});
    const [ImageDiv, setImageDiv] = useState(null);
    const [divSize, setDivSize] = useState(150);
    useEffect(() => {
        if(props.size !== undefined) setDivSize(props.size);
    }, [props.size]);
    useEffect(() => {
        const tempV = new THREE.Vector3(props.position[0], props.position[1], props.position[2]);
        tempV.project(props.camera);
        const x = (tempV.x *  .5 + .5) * props.screen.width;
        const y = (tempV.y * -.5 + .5) * props.screen.height;
        setState({transform: `translate(-50%, -50%) translate(${x}px,${y}px)`});
    }, [props.cameraRotation, props.position, props.camera, props.screen]);
    useEffect(() => {
        setImageDiv(styled.div`
            transform: rotateX(32deg);
            width: ${divSize}px;
            height: ${divSize}px;
            -webkit-mask-box-image: url("/assets/white-circle-floor.png");
            background-color: ${props.color};
        `);
    }, [props.color, divSize]);
    return (
        <mesh position={props.position} style={state}>
            <Html center className={tourcss.hoverButton}>
                <div onDoubleClick={evt => {
                    if(evt.button === 0) {
                        props.onSelect(props.indexRedirect);
                    }
                }}>
                    {ImageDiv !== null ? <ImageDiv/> : null}
                    <div style={{top: 0, marginTop: -divSize/4, color: props.color, width: divSize, display: "flex", justifyContent: "center"}}>{props.locationName}</div>
                </div>
            </Html>
        </mesh>
    )
}

function LocationContainer(props) {
    return (
        <FirstContext.Consumer>
            {value => 
                <LocationPoint {...props} screen={value.screenSize} cameraRotation={value.cameraRotation}/>
            }
        </FirstContext.Consumer>
    )
}

function Room(props) {
    const [points, setPoints] = useState([]);
    const [locations, setLocations] = useState([]);
    const [url, setUrl] = useState(null);
    useEffect(() => {
        if(props.beforeLoad !== undefined && props.beforeLoad !== null) {
            props.beforeLoad();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        const camera = props.three.camera;
        if(camera.zoom !== initialZoom) {
            const resCam = props.resetCameraPosition;
            resCam(camera);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.nRoom, props.three.camera, props.resetCameraPosition]);
    useEffect(() => {
        const tag = document.querySelector("#videos video[src=\""+props.info.url+"\"]");
        tag.play();
        if(url !== null) {
            const ptag = document.querySelector("#videos video[src=\""+url+"\"]");
            ptag.pause();
            ptag.currentTime = 0;
        }
        setUrl(props.info.url);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.info.url]);
    useEffect(() => {
        const points = props.info.points;
        const cartAddF = props.onCartAdd;
        const rePoints = [];
        if (points !== undefined) {
            for (var i = 0; i < points.length; i++) {
                var point = points[i];
                rePoints.push(
                    <Item key={uuidv4()} {...point} onCartAdd={item => cartAddF(item)}/>
                );
            }
        }
        setPoints(rePoints);
    }, [props.info.points, props.onCartAdd]);
    useEffect(() => {
        const locations = props.info.locations;
        const locChangeF = props.onLocationChange;
        const locPoints = [];
        if(locations !== undefined) {
            for(var i = 0; i < locations.length; i++) {
                const location = locations[i];
                locPoints.push(
                    <LocationContainer key={uuidv4()} {...location} camera={props.three.camera} onSelect={idx => locChangeF(idx, props.three.camera)}/>
                );
            }
        }
        setLocations(locPoints);
    }, [props.info.locations, props.three.camera, props.onLocationChange]);
    return (
        <group>
            <mesh onPointerDown={props.onClick} ref={props.sceneRef} scale={new THREE.Vector3(-1,1,1)}>
                <sphereBufferGeometry attach="geometry" args={[500, 60, 40]}/>
                <meshBasicMaterial attach="material" map={props.texture} side={THREE.BackSide}/>
            </mesh>
            {points}
            {locations}
        </group>
    )
}

export class R3DTour extends React.Component {
    constructor(props) {
        super(props);
        this.scene = React.createRef();
        this.controls = React.createRef();
        this.state = {
            width: 0,
            height: 0,
            room: 0,
            cameraPosition: [0, 0, 0.1],
            maps: this.props.maps,
            blur: 0,
            cameraRotation: [0,0]
        }
    }

    tween = null;

    static getDerivedStateFromProps(nextProps, prevState) {
        if (!lodash.isEqual(prevState.maps, nextProps.maps)) {
            return { maps: nextProps.maps };
        } else return null;
    }

    updateDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    keyDown = evt => {
        if(evt.keyCode === 13 && this.controls.current !== null) {
            const rot = [this.controls.current.azimuthAngle, this.controls.current.polarAngle];
            console.log(rot);
        }
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener('resize', this.updateDimensions);
        //document.addEventListener("keydown", this.keyDown)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
        //document.addEventListener("keydown", this.keyDown)
    }

    setPoint = (event, three) => {
        const { camera } = three;
        switch (event.button) {
            case 0:
                break;
            case 2:
                var mouse = new THREE.Vector2();
                var raycaster = new THREE.Raycaster();
                mouse.x = (event.clientX / this.state.width) * 2 - 1;
                mouse.y = - (event.clientY / this.state.height) * 2 + 1;
                raycaster.setFromCamera(mouse, camera);
                var objects = raycaster.intersectObject(this.scene.current);
                if (objects.length > 0) {
                    const point = objects[0].point.multiplyScalar(0.1);
                    console.log(point);
                }
                break;
            case 1:
                break;
            default:
                break;
        }
    }

    cartAdd = item => {
        if ("onCartAdd" in this.props) this.props.onCartAdd(item);
    }

    locationChange = (idx, camera) => {
        var zoom = {
            value: camera.zoom,
            blur: 0
        };
        var zoomEnd = {
            value: initialZoom+0.5,
            blur: 5
        };
        this.tween = new TWEEN.Tween(zoom).to(zoomEnd, 500);
        this.tween.onStart(() => {
            this.controls.current.zoom(1.5, true);
        });
        this.tween.onUpdate(() => {
            camera.zoom = zoom.value;
            this.setState({blur: zoom.blur});
        });
        this.tween.onComplete(() => {
            if("initialRotation" in this.state.maps[idx] && this.controls.current !== null) {
                const rot = this.state.maps[idx].initialRotation;
                this.controls.current.rotateTo(rot[0], rot[1], false);
            }
            this.setState({room: idx});
        })
        this.tween.start();
        animate();
    }

    resetCamera = camera => {
        var zoom = {
            value: camera.zoom,
            blur: this.state.blur
        };
        var zoomEnd = {
            value: initialZoom,
            blur: 0
        };
        this.tween = new TWEEN.Tween(zoom).to(zoomEnd, 700);
        this.tween.onStart(() => {
            this.controls.current.zoom(-1.5, true);
        });
        this.tween.onUpdate(() => {
            camera.zoom = zoom.value;
            this.setState({blur: zoom.blur});
        });
        this.tween.start();
        animate();
    }

    render() {
        return (
            <Canvas camera={{ position: this.state.cameraPosition }} style={{filter: 'blur('+this.state.blur+'px)'}}>
                <InitThree render={three => {
                    return (
                        <FirstContext.Provider value={{
                            screenSize: {width: this.state.width, height: this.state.height}, 
                            cameraRotation: this.state.cameraRotation
                        }}>
                            <Controls dampingFactor={0.05} polarRotateSpeed={-0.5} azimuthRotateSpeed={-0.5} three={three} ref={this.controls}
                                onRotationChange={angle => {
                                    this.setState({cameraRotation: angle});
                                }}
                            />
                            <Suspense fallback={
                                <Html>
                                    <Loader />
                                </Html>
                            }>
                                <Preload maps={this.state.maps} render={textures => {
                                    const i = this.state.room >= textures.length ? (this.state.room % textures.length) : this.state.room;
                                    return (
                                        <Room
                                            nRoom={i+1}
                                            beforeLoad={() => {
                                                if("initialRotation" in this.state.maps[i] && this.controls.current !== null) {
                                                    const rot = this.state.maps[i].initialRotation;
                                                    this.controls.current.rotateTo(rot[0], rot[1], false);
                                                }
                                            }}
                                            three={three}
                                            texture={textures[i]}
                                            info={this.state.maps[i]}
                                            onClick={event => this.setPoint(event, three)}
                                            onCartAdd={this.cartAdd}
                                            sceneRef={this.scene}
                                            onLocationChange={this.locationChange}
                                            resetCameraPosition={this.resetCamera}
                                        />
                                    )
                                }} />
                            </Suspense>
                        </FirstContext.Provider>
                    )
                }} />
            </Canvas>
        )
    }
}