import React from 'react'
import {R3DTour as Tour} from './R3DTour';
import './App.css';
import { BiCart } from 'react-icons/bi';
import { IoMdCloseCircle } from 'react-icons/io';

const maps = [
  {url: '/assets/1.mp4', initialRotation: [1.1969568594787154, 1.3260404423636747], points: [
    {
      position: [-21.227846686796017, -4.4332331427372695, 44.98283737687228], 
      description: 'One of the coolest sneaker cult-brands ever, for which retro models there are people willing to camp outside stores. The brand was founded in 1905 and began to manufacture sneakers that were originally designed to improve shoe fit - a special design with three support points to provide greater balance and comfort in the shoe. Their model Tracster, from 1960, was the first professional running shoe and the model W320 was a running shoe designed especially for women only.', 
      title: 'NEW BALANCE 1500', imageURL: '/assets/newbalance1500.jpg'
    },
    {
      position: [-35.82097851644151, -30.342007768885964, 17.023778574821904], 
      description: 'One of the coolest sneaker cult-brands ever, for which retro models there are people willing to camp outside stores. The brand was founded in 1905 and began to manufacture sneakers that were originally designed to improve shoe fit - a special design with three support points to provide greater balance and comfort in the shoe. Their model Tracster, from 1960, was the first professional running shoe and the model W320 was a running shoe designed especially for women only.', 
      title: 'NEW BALANCE 1500', imageURL: '/assets/newbalance1500.jpg'
    }
  ],
    locations: [
      {position: [-25.971784162212124, -28.737114727071912, -31.507586827500162], indexRedirect: 1, color: 'red', locationName: 'Vista n2'}
    ]
  },
  {url: '/assets/2.mp4', initialRotation: [1.538944533615491, 1.4199194117345533],
  points: [
    {
      position: [-48.54546935681878, 5.91805010968335, -10.189249069120311], 
      description: 'One of the coolest sneaker cult-brands ever, for which retro models there are people willing to camp outside stores. The brand was founded in 1905 and began to manufacture sneakers that were originally designed to improve shoe fit - a special design with three support points to provide greater balance and comfort in the shoe. Their model Tracster, from 1960, was the first professional running shoe and the model W320 was a running shoe designed especially for women only.', 
      title: 'NEW BALANCE 1500', imageURL: '/assets/newbalance1500.jpg'
    }
  ],
  locations: [
    {position: [20.646066718378407,-24.329524968350324, 38.388674848802964], indexRedirect: 0, color: 'red', locationName: 'Vista n1'},
    {position: [-35.209864872533444, -21.875857695985466, -27.81613841873707], indexRedirect: 2, color: 'red', size: 100, locationName: 'Vista n3'}
  ]},
  {url: '/assets/3.mp4', initialRotation: [1.2975414695189447, 1.4198998863884233],
  points: [
    {
      position: [31.065507076362408, -15.89380347403258, -35.738857703604445], 
      description: 'One of the coolest sneaker cult-brands ever, for which retro models there are people willing to camp outside stores. The brand was founded in 1905 and began to manufacture sneakers that were originally designed to improve shoe fit - a special design with three support points to provide greater balance and comfort in the shoe. Their model Tracster, from 1960, was the first professional running shoe and the model W320 was a running shoe designed especially for women only.', 
      title: 'NEW BALANCE 1500', imageURL: '/assets/newbalance1500.jpg'
    },
    {
      position: [-9.45943534783398, -18.3982323853921, -45.481976640924614], 
      description: 'One of the coolest sneaker cult-brands ever, for which retro models there are people willing to camp outside stores. The brand was founded in 1905 and began to manufacture sneakers that were originally designed to improve shoe fit - a special design with three support points to provide greater balance and comfort in the shoe. Their model Tracster, from 1960, was the first professional running shoe and the model W320 was a running shoe designed especially for women only.', 
      title: 'NEW BALANCE 1500', imageURL: '/assets/newbalance1500.jpg'
    }
  ],
  locations: [
    {position: [-45.09658208599203, -14.988212939802612, -15.393865520996876], indexRedirect: 1, color: 'red', size: 100, locationName: 'Vista n2'}
  ]}
]

class MatterportView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="matterportButton" style={{visibility: this.state.active ? 'hidden' : 'visible'}} onClick={() => this.setState({active: true})}>
          <img src="/assets/plan.png" style={{width: "80%", marginTop: 4, marginLeft: 4}} alt=""/>
        </div>
        <div className="matterportFrame" style={{visibility: this.state.active ? 'visible' : 'hidden'}}>
          <iframe title="Matterport Frame" allowFullScreen={true} src="https://my.matterport.com/show/?m=JVuiBtyUPoW&amp;utm_source=hit-content-embed"/>
          <IoMdCloseCircle size={32} color={"#bd0202"} style={{position: "absolute", top: 5, left: 5}} onClick={() => this.setState({active: false})}/>
        </div>
      </React.Fragment>
    )
  }
}

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
      cartCounter: 0,
      fullscreen: false
    }
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  render() {
    return (
      <React.Fragment>
        <div className="absoluteContainer" style={{width: this.state.width, height: this.state.height}}>
          <MatterportView/>
          <div className="cartButton">
            <div style={{minWidth: 40, height: 30, color: 'white', fontSize: 22, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <span>{this.state.cartCounter}</span>
            </div>
            <BiCart size={30} color="white"/>
          </div>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', bottom: 15, left: 0, position: 'absolute', width: '100vw'}}>
            <span style={{color: 'white'}}>Per ulteriori informazioni <a target={'_blank'} rel="noreferrer" href={'https://www.mtmproject.com/contatti/'}>contattaci</a></span>
          </div>
        </div>
        <Tour maps={maps} onCartAdd={item => this.setState(prevState => ({cartCounter: prevState.cartCounter + 1}))}/>
      </React.Fragment>
    )
  }
}

export default App;
